<template>
  <App
    :title="titleName + '排行榜'"
    left-arrow
    class="rank"
  >
    <div class="top-label">
      <Label
        :labelData="lables"
        :tabOn="tabOn"
        @tabName="tabNameChange"
        @onChange="labelChange"
        class="topLabel"
      />
    </div>

    <div class="container">
      <div class="table-tr">
        <div
          v-for="(items, ind) in list"
          v-auth="items.authCode"
          :key="ind"
          class="table-td"
        >
          {{items.title}}
        </div>
      </div>

      <div
        v-for="(rank, index) in rankList"
        :key="index + '1'"
        class="table-tr"
      >
        <div
          v-for="(items, indedx) in list"
          class="table-td"
          v-auth="items.authCode"
          :key="indedx"
        >
          {{rank[items.label] ? rank[items.label] : 0}}
        </div>
      </div>
    </div>
    <footer>
      <Label
        :labelData="timeLable"
        @onChange="timeChange"
      />
    </footer>
  </App>
</template>

<script>
import { Toast } from 'vant';
import { leadsRank, oppRank, drRank, orderRank, tdCompleteRank, tdShowRank } from '@/api/chargequery'
import Label from './component/index';
import 'vant/lib/list/style'
import '@/style/vant-patch.less'
import '@/style/common.less';
import './index.less'
export default {
  name: 'rank',
  data () {
    return {
      tabType: '3',
      dateRange: 'month',
      list: [
        { title: '顾问', label: 'name' },
        { title: '线索', label: 'total' },
        { title: '全国排名', label: 'countryRanking', authCode: 'ScoreRanking-Nationwide' },
        { title: '大区排名', label: 'regionRanking', authCode: 'ScoreRanking-Region' },
        { title: '小区排名', label: 'areaRanking', authCode: 'ScoreRanking-Plot' },
        { title: '门店排名', label: 'storeRanking', authCode: 'ScoreRanking-Store' }
      ],
      rankList: [],
      loading: false,
      finished: false,
      tabOn: '',
      timeOn: 'month',
      lables: {
        list: [
          {
            title: '大区',
            label: '2',
            authCode: 'RankingList-Region'
          },
          {
            title: '小区',
            label: '3',
            authCode: 'RankingList-Community'
          },
          {
            title: '门店',
            label: '5',
            authCode: 'RankingList-Shop'
          },
          {
            title: '顾问',
            label: 'user',
            authCode: 'RankingList-Counselor'
          }
        ]
      },
      timeLable: {
        list: [
          {
            title: '本月',
            label: 'month'
          },
          {
            title: '本周',
            label: 'week'
          },
          {
            title: '今日',
            label: 'day'
          }
        ]
      },
      name: '',
      roleCode: '',
      titleName: '',
      shopName: '',
      authCodeList: [],
      tableName: ''
    }
  },
  components: {
    Label,
    // List
  },
  watch: {
    list: {
      immediate: true,
      deep: true,
      // eslint-disable-next-line
      handler () { }
    }
  },
  methods: {
    tabNameChange (name) {
      this.list.forEach(e => {
        if (e.label === 'name') {
          e.title = name
        }
      })

    },
    // tab切换
    labelChange (on) {
      this.tabType = this.tabOn = on.label
      this.shopName = on.title
      this.list.forEach(ke => {
        if (ke.label === 'name') {
          ke.title = this.shopName
        }
      })
      this.getData()
    },
    // 时间切换
    timeChange (on) {
      this.dateRange = this.timeOn = on.label
      this.getData()
    },
    // 线索
    async getLeads () {
      const res = await leadsRank({ tabType: this.tabType, dateRange: this.dateRange })
      Toast.clear()
      if (res.success) {
        this.rankList = res.data
      } else {
        Toast(res.msg)
      }
    },
    // 商机
    async getOpp () {
      const res = await oppRank({ tabType: this.tabType, dateRange: this.dateRange })
      Toast.clear()
      if (res.success) {
        this.rankList = res.data
      } else {
        Toast(res.msg)
      }
    },
    // 意向金
    async getDr () {
      const res = await drRank({ tabType: this.tabType, dateRange: this.dateRange })
      Toast.clear()
      if (res.success) {
        this.rankList = res.data
      } else {
        Toast(res.msg)
      }
    },
    // 大定净增
    async getOrder () {
      const res = await orderRank({ tabType: this.tabType, dateRange: this.dateRange })
      Toast.clear()
      if (res.success) {
        this.rankList = res.data
      }
    },
    // 试驾完成
    async getTd () {
      const res = await tdCompleteRank({ tabType: this.tabType, dateRange: this.dateRange })
      Toast.clear()
      if (res.success) {
        this.rankList = res.data
      } else {
        Toast(res.msg)
      }
    },
    // td-show
    async getNoshow () {
      const res = await tdShowRank({ tabType: this.tabType, dateRange: this.dateRange })
      Toast.clear()
      if (res.success) {
        this.rankList = res.data
      } else {
        Toast(res.msg)
      }
    },
    getData () {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      switch (this.name) {
        case 'clue':
          this.getLeads()
          this.titleName = '线索'
          break;
        case 'opp':
          {
            this.getOpp()
            this.titleName = '商机'
            this.list.forEach(ke => {
              if (ke.label === 'total') {
                ke.title = this.titleName
              }
            })
          }
          break
        case 'dr': {
          this.getDr()
          this.titleName = '意向金净增'
          this.list.forEach(ke => {
            if (ke.label === 'total') {
              ke.title = this.titleName
            }
          })
        }
          break
        case 'order': {
          this.getOrder()
          this.titleName = '大定净增'
          this.list.forEach(ke => {
            if (ke.label === 'total') {
              ke.title = this.titleName
            }
          })
        }
          break
        case 'td':
          {
            this.getTd()
            this.titleName = '试乘试驾完成'
            this.list.forEach(ke => {
              if (ke.label === 'total') {
                ke.title = this.titleName
              }
            })
          }
          break
        case 'noshow':
          {
            this.getNoshow()
            this.titleName = 'TD No-Show'
            this.list.forEach(ke => {
              if (ke.label === 'total') {
                ke.title = this.titleName
              }
            })
          }
          break
      }
    },
    init () {
      this.name = this.$route.query.rankName
      this.roleCode = this.$storage.get('roleCode')
      this.authCodeList = this.$storage.get('authorized')
      switch (this.roleCode) {
        case 'RE002':
        case 'RE003':
          this.tabOn = '2'
          this.tabType = '2'
          this.tableName = '大区'
          break
        case 'RE004':
          this.tabOn = '3'
          this.tabType = '3'
          this.tableName = '小区'
          break
        case 'RE005':
          this.tabOn = '5'
          this.tabType = '5'
          this.tableName = '门店'
          break
        case 'RE006':
        case 'RE010':
          this.tabOn = 'user'
          this.tabType = 'user'
          this.tableName = '顾问'
      }
      this.loading = false;
      this.getData()
      this.list.forEach(e => {
        if (e.label === 'name') {
          e.title = this.tableName
          console.log('e', e.title);

        }
      })
      this.list = [...this.list]
      console.log(this.list);

    }
  },
  mounted () {
    this.init()
  }
}
</script>
